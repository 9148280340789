@import './../../assets/scss/variables';
@import './../../assets/scss/mixins';

.dashboard-page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &--container {
    padding: 40px 48px;
    background-color: $primaryLighter;
    flex: 1;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 40px;

    h1 {
      @include font-black(26px);
      color: $primary;
      margin: 0;
      letter-spacing: -1.04px;
    }

    .subtitle {
      @include font-regular(16px);
      color: $primary;
      margin: 0;
    }
  }

  .body {
    display: flex;

    .dashboard-tabs {
      background-color: $white;
      display: flex;
      padding: 30px 24px;
      flex-direction: column;
      gap: 32px;
      align-self: stretch;
      border-radius: 12px;

      &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .MuiBox-root {
        padding-bottom: 24px;
        border-color: $primaryLighter;
      }

      .MuiTabs-root {
        min-height: 0;
      }

      .MuiTabs-flexContainer {
        display: flex;
        align-items: center;
        gap: 8px;

        button {
          @include font-regular(13px);
          color: $primary;
          border-radius: 16px;
          background-color: $white;
          padding: 8px 12px;
          text-transform: inherit;
          min-height: 0;
          line-height: 20px;

          &.Mui-selected {
            background-color: $primaryMedium;
            @include font-bold(13px);
            color: $white;
          }
        }
      }

      .MuiTabs-indicator {
        display: none;
      }

      .simple-tabpanel {
        .MuiBox-root {
          padding: 0;
        }

        .no-data {
          @include font-bold(13px);
          line-height: 20px;
          color: $primary;
          margin: 0;
        }
      }

      .filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 48px;

        &__container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
        }
      }

      .tabpanel-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .stats {
          color: $primary;
          @include font-regular(13px);

          span {
            @include font-bold(13px);
          }
        }
      }

      .tabpanel-cards {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-bottom: 16px;

        .card {
          padding: 20px;
          flex: 1 0 0;
          border-radius: 8px;
          text-align: left;
          flex-direction: column;
          align-items: flex-start;

          &:hover {
            cursor: pointer;
            opacity: 1;

            .chevron-right {
              background-color: $black;

              .icon {
                background-image: url('./../../assets/icons/icon-chevron-right.svg');
              }
            }
          }

          &.approved {
            background-color: $tertiaryLight;

            &:hover {
              background-color: $tertiaryMedium;
            }
          }

          &.rejected {
            background-color: $secondaryLighter;

            &:hover {
              background-color: $secondaryLight;
            }
          }

          &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            width: 100%;

            p {
              @include font-regular(15px);
              line-height: 24px;
              text-transform: uppercase;
              margin: 0;
            }

            .card-icon {
              height: 20px;
              width: 20px;
            }
          }

          &-body {
            align-items: center;
            display: flex;
            gap: 8px;
            margin: 0 0 35px 0;

            span {
              @include font-regular(13px);
              color: $primary;
              opacity: 0.6;
            }

            .bold {
              @include font-black(26px);
              color: $primary;
              letter-spacing: -1.04px;
              line-height: 34px;
              opacity: 1;
            }
          }

          &-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            span {
              @include font-regular(13px);
              color: $black;
              line-height: 20px;
            }

            .chevron-right {
              border-radius: 8px;
              width: 28px;
              height: 29px;
              background-color: $white;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.1);
              position: relative;

              .icon {
                background: url('./../../assets/icons/icon-chevron-right-black.svg') no-repeat;
                background-size: contain;
                width: 7px;
                height: 10px;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }

      .rejection-type {
        border-radius: 8px;
        padding: 20px;
        border: 1px solid $primaryLight;

        .title-container {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          align-items: center;
        }

        .title {
          @include font-regular(15px);
          color: $primary;
          line-height: 24px;
          text-transform: uppercase;
          margin: 0;
          text-align: left;
        }

        .block-piechart {
          display: flex;
          justify-content: space-between;

          .css-1k89n7c-MuiResponsiveChart-container {
            flex-grow: inherit;
          }
        }

        .block-legend {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 12px;
          justify-content: center;
        }

        .legend-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .legend-square {
          display: flex;
          align-items: center;

          .square {
            border-radius: 6px;
            height: 4px;
            width: 16px;
            margin-right: 12px;
          }

          .label {
            @include font-regular(13px);
            color: $primaryDarken;
            line-height: 20px;
            margin: 0;
            text-transform: capitalize;
          }
        }

        .legend-percentage {
          margin: 0;
          @include font-bold(13px);
          color: $primaryDarker;
          line-height: 20px;
        }

        .no-data {
          padding: 37px 0px;
          @include font-bold(13px);
          color: $primary;
          text-align: center;
          line-height: 20px;
        }
      }
    }
  }
}
