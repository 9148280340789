@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.add-edit-modal {
  background-color: $primaryLighter;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: -7px 0px 8px 0px rgb(0 0 0 / 12%);
  width: 640px;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  transition: right 600ms 100ms ease-out;
  height: 100vh;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .add-edit-modal-top {
    flex: 1;
  }

  .add-edit-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 8px 24px;

    h3 {
      @include font-black(15px);
      color: $primary;
      line-height: 24px;
      margin: 0;
    }
  }

  .add-edit-modal-body {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .block-datepicker,
    .block-textfield,
    .block-select {
      width: calc(50% - 12px);
      
      &.block-wide {
        width: calc(100% - 12px);
      }
    }

    .subtitle {
      @include font-black(15px);
      color: $primary;
      line-height: 24px;
      width: 100%;
      flex: auto;
      margin: 0 0 32px 0;
    }
  }

  .add-edit-modal-footer {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-side {
      display: flex;
      gap: 16px;
    }
  }

  .form-modal__container {
    background-color: $white;
    box-sizing: border-box;
    padding-top: 40px;
    height: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    form > div {
      margin-bottom: 20px;
    }
  }

  &.open {
    right: 0;
  }

  &.close {
    right: -640px;
  }
}
