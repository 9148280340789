.image-modal {
    .image-container {
        border-radius: 8px;
        height: 36px;
        width: 36px;
        border: 0;
        padding: 0;
        cursor: pointer !important;
        
        img {
            border-radius: 8px;
            height: 100%;
            width: 100%;
        }
    }
}