@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.oryx-stats {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__container {
    padding: 48px;
  }

  &__header {
    background-color: $primaryLighter;
    padding: 40px 48px 20px 48px;
    gap: 16px;

    .header-container {
      display: flex;
      justify-content: space-between;
    }

    p {
      margin: 0;
    }

    .right-side,
    .left-side {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .left-side {
      .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .filters-container {
      display: flex;
      margin-top: 20px;
      padding: 16px 0;
      gap: 12px;

      .filter-chip {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        border-radius: 16px;
        border: 1px solid $primary;

        span {
          @include font-regular(12px);
          color: $primary;
          line-height: 18px;
          padding: 0;
          margin-right: 8px;
        }

        svg {
          margin: 0;
        }
      }
    }
  }

  .search-input {
    .MuiInputBase-root {
      padding: 14px 12px;
      border-radius: 12px;
      background-color: $white;
      width: 240px;
      height: 42px;
    }

    input {
      padding: 0;
      overflow: hidden;
    }

    fieldset {
      border: none;
    }

    .search-input__end {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin-left: 5px;
    }
  }

  &__body {
    background: url('./../../../assets/images/stats-bg.png') no-repeat;
    background-size: cover;
    flex: 1;
    padding: 0 48px 40px 48px;
    position: relative;

    &.empty-table {
      .mantine-vl6xmj {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      height: 50px;
      width: 48px;
      background-color: $primaryLighter;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 9;
    }

    &::before {
      content: '';
      position: absolute;
      height: 50px;
      width: 48px;
      background-color: $primaryLighter;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 9;
    }

    .mantine-Paper-root {
      border: none;
      box-shadow: none;
      background-color: transparent;
    }
  }
}
