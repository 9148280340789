@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.block-select {
  margin-bottom: 32px;

  &.disabled {
    .css-1fdsijx-ValueContainer,
    .css-3iigni-container {
      background-color: $primaryLight;
    }

    .css-3iigni-container .css-16xfy0z-control {
      background-color: $primaryLight;
    }
  }

  .css-3iigni-container,
  .css-16xfy0z-control {
    border-radius: 12px;
  }

  .css-13cymwt-control,
  .css-16xfy0z-control,
  .css-t3ipsp-control {
    border: none !important;
    box-shadow: none !important;
    border-radius: 12px;
    padding: 0 12px 0 0;
  }

  .css-1fdsijx-ValueContainer {
    background-color: $white;
    border-radius: 12px;
    padding: 14px 12px;
    height: -webkit-fill-available;
    text-transform: capitalize;
  }

  .css-1u9des2-indicatorSeparator,
  .css-894a34-indicatorSeparator {
    display: none;
  }

  .css-1xc3v61-indicatorContainer {
    background-image: url('./../../../assets/icons/icon-selectfield-right.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    svg {
      display: none;
    }
  }

  .css-15lsz6c-indicatorContainer {
    background-image: url('./../../../assets/icons/icon-selectfield-down.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    svg {
      display: none;
    }
  }

  .css-pogzpp-loadingIndicator,
  .css-o9ehiq-loadingIndicator {
    span {
      height: 8px;
      width: 8px;
    }
  }

  .css-1nmdiq5-menu {
    border-radius: 12px;
    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.1);
    z-index: 9;
    text-transform: capitalize;
  }

  .css-1n6sfyn-MenuList {
    background-color: $white;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;

    div {
      @include font-regular(12px);
      border-radius: 8px;
      color: $primary;
      line-height: 18px;
      padding: 8px;
      background-color: $white;

      &:hover {
        background-color: $primaryLight;
      }

      &.css-tr4s17-option {
        background-color: $primaryLight;
      }
    }
  }

  .css-qbdosj-Input,
  .css-olqui2-singleValue {
    color: $primary;
    @include font-regular(13px);
    line-height: 20px;
    margin: 0;
    padding: 0;
  }

  .MuiFormControl-root {
    width: 100%;
    margin: 0;
  }

  .MuiFormLabel-root {
    @include font-bold(13px);
    color: $primary !important;
    line-height: 20px;
    margin-bottom: 16px;
    position: static;
    transform: none;
    border-radius: 12px;
  }

  .css-13cymwt-control,
  .css-16xfy0z-control,
  .css-t3ipsp-control {
    height: 48px;
    box-sizing: border-box;
  }

  .css-1h01tm3-Input {
    height: 20px;
    margin: 0;
  }

  &.error {
    .css-13cymwt-control,
    .css-t3ipsp-control {
      border: 1px solid $secondaryMedium !important;
    }

    .MuiFormHelperText-root {
      margin-top: 8px;
      @include font-regular(13px);
      margin-left: 0;
      color: $secondaryMedium;
      line-height: 20px;
    }
  }
}
