@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.block-custom-select {
  margin-bottom: 32px;

  label {
    @include font-bold(13px);
    color: $primary;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .custom-select {
    width: 100%;

    .icon {
      height: 15px;
      width: 15px;
    }

    .MuiInputBase-root:hover,
    input:hover {
      cursor: pointer;
    }

    .MuiInputBase-root {
      padding: 14px 12px;
      border-radius: 12px;
      background-color: $white;
    }

    input {
      padding: 0;
      color: $primary;
      @include font-regular(13px);
      overflow: hidden;
      line-height: 20px;

      &::placeholder {
        color: $primary !important;
        opacity: .4 !important;
      }
    }

    fieldset {
      border: none;
    }
  }
}

.custom-select-modal {
  background-color: $primaryLighter;
  box-sizing: border-box;
  overflow-x: hidden;
  box-shadow: -7px 0px 8px 0px rgb(0 0 0 / 12%);
  width: 400px;
  position: absolute;
  right: 0;
  transition: right 600ms 100ms ease-out;

  .custom-select-header {
    position: fixed;
    width: -webkit-fill-available;
    z-index: 9;

    .container {
      display: flex;
      align-items: center;
      background-color: $white;
      padding: 8px 24px;
      border-bottom: 1px solid $primaryLight;
      gap: 16px;
    }

    h3 {
      @include font-black(15px);
      color: $primary;
      line-height: 24px;
      margin: 0;
    }

    .custom-select__search-input {
      display: block;

      .MuiInputBase-root {
        padding: 14px 12px;
        border-radius: 0;
        background-color: $white;
        display: flex;
      }

      input {
        padding: 0;
        overflow: hidden;
        width: calc(100% - 51px);
      }

      fieldset {
        border: none;
      }

      .search-input__end {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        margin-left: 5px;
      }
    }
  }

  .custom-select-body {
    padding: 24px;
    overflow-y: scroll;
    margin-top: 108px;

    .options {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
    }

    .option-card {
      border-radius: 12px;
      box-shadow: none;
      width: 100%;

      .MuiCardContent-root {
        padding: 14px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: $primary;
          @include font-regular(13px);
          text-transform: capitalize;
          line-height: 20px;
        }

        .MuiCheckbox-root {
          padding: 0;

          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
