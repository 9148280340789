@import './assets/scss/mixins';
@import './assets/scss/variables';

@font-face {
  font-family: 'Arial-Bold';
  src: url(./assets/fonts/Arial-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Arial-Black';
  src: url(./assets/fonts/Arial-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Arial-Regular';
  src: url(./assets/fonts/Arial-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    @include font-regular(14px);
  }

  .oryx-selectfield-paper {
    padding: 8px;
    margin-top: 10px;
    border-radius: 12px;
    background: $white;
    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.1);
    min-width: 270px !important;
    max-height: 300px !important;

    .MuiList-root {
      padding: 0;

      .MuiButtonBase-root {
        @include font-regular(12px);
        padding: 8px;
        color: $primary;
        line-height: 18px;
      }
    }
  }

  // SNACKBAR STYLES
  .oryx-snackbar {
    top: 40px;
    right: 46px;

    &.success {
      .MuiPaper-root {
        background: $tertiary;
      }
    }

    &.error {
      .MuiPaper-root {
        background: $secondaryMedium;
      }
    }

    .MuiPaper-root {
      padding: 16px;
      border-radius: 8px;
    }

    .MuiSnackbarContent-message {
      @include font-regular(13px);
      color: $primary;
      line-height: 20px;
      padding: 0;
    }
  }
}
