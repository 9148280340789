@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.btn {
  border-radius: 16px !important;
  @include font-bold(13px);
  padding: 8px 12px !important;
  gap: 8px;
  width: fit-content;
  text-transform: inherit !important;
  height: 42px;

  &-round,
  &-square {
    background-color: $white !important;
    padding: 10px !important;
    border-radius: 16px !important;

    &:hover {
      background-color: $white !important;
    }
  }

  &-round {
    &.no-padding {
      padding: 0 !important;
      height: auto;
    }
  }

  &.box-shadow {
    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.1);

    &--inactive {
      box-shadow: 0px 12px 16px 0px rgba(227, 24, 55, 0.2);
    }

    &--active {
      box-shadow: 0px 12px 16px 0px rgba(52, 193, 108, 0.2);
    }
  }

  .badge {
    @include font-bold(12px);
    background: $white;
    color: $black;
    line-height: 18px;
    border-radius: 40px;
    display: flex;
    width: 26px;
    padding: 4px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &-pl {
    padding: 16px 20px !important;
  }

  &-primary {
    background-color: $primary !important;
    color: $white !important;
  }

  &-secondary {
    background-color: $white !important;
    color: $black !important;

    .badge {
      background-color: $primaryLighter;
    }
  }

  &-tertiary {
    background-color: $tertiary !important;
    color: $primary !important;
  }

  &-quinary {
    background-color: $primaryMedium !important;
    color: $white !important;
  }

  &-black {
    background-color: $black !important;
    color: $white !important;
  }

  &-senary {
    background: $yellowGradient !important;
    color: $black !important;
    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      background: $white !important;
    }
  }

  &-active {
    background-color: $tertiary !important;
    color: $primary !important;
  }

  &-inactive {
    background-color: $secondaryMedium !important;
    color: $primary !important;
  }

  &.tabs {
    @include font-regular(13px);
    color: $primary;
    line-height: 20px;
    height: auto;
    min-width: auto;
    padding: 8px 12px !important;

    &.active {
      @include font-bold(13px);
    }
  }

  &-outlined {
    border: 1px solid !important;

    &:hover {
      border: 1px solid $primaryLight !important;
    }
  }

  &-transparent {
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  &-text {
    background-color: transparent !important;
  }
}
