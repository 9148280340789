@import './../../../assets/scss/variables';

.App {
  overflow-y: hidden;

  &-container {
    display: flex;

    &__right-side {
      flex: 1;
    }

    .main {
      width: calc(100% - 190px);
      overflow-y: scroll;
    }
  }
}
