@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.oryx-details {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__container {
    padding: 48px;
  }

  &__header {
    background-color: $primaryLighter;
    padding: 40px 48px 20px 48px;
    gap: 16px;

    .header-container {
      display: flex;
      justify-content: space-between;
    }

    .filters-container {
      display: flex;
      margin-top: 20px;
      padding: 16px 0;
      gap: 12px;

      .filter-chip {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        border-radius: 16px;
        border: 1px solid $primary;

        span {
          @include font-regular(12px);
          color: $primary;
          line-height: 18px;
          padding: 0;
          margin-right: 8px;
        }

        svg {
          margin: 0;
        }
      }
    }

    p {
      margin: 0;
    }

    .left-side {
      display: flex;
      align-items: center;
      gap: 16px;

      .description {
        text-align: left;

        h1 {
          text-transform: capitalize;
        }
      }
    }

    .right-side {
      display: flex;
      align-items: center;
      gap: 16px;

      .agent-badge {
        border-radius: 8px 2px;
        padding: 12px 24px;
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          height: 13px;
        }

        p {
          @include font-bold(12px);
          color: $primary;
          line-height: 18px;
        }

        &.active {
          background-color: $tertiaryMedium;
        }

        &.inactive {
          background-color: $secondaryMedium;
        }
      }
    }
  }

  &__body {
    background: url('./../../../assets/images/stats-bg.png') no-repeat;
    background-size: cover;
    padding: 40px 48px;
    position: relative;
    flex: 1;

    .cards-container {
      display: flex;
      align-items: stretch;
      gap: 16px;
    }

    .card {
      background-color: $white;
      border-radius: 12px;
      margin-bottom: 40px;
      padding: 24px;
      flex: 1;

      h3 {
        @include font-bold(15px);
        color: $primary;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 24px;
        text-align: left;
      }

      .card-description {
        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .label,
          .value {
            color: $primary;
            line-height: 20px;
            margin: 0;
          }

          .label {
            @include font-regular(13px);
          }

          .value {
            @include font-bold(13px);
            text-transform: capitalize;
          }

          .value span {
            display: block;
          }
        }
      }
    }

    .dashboard-tabs__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .MuiTabs-root {
      min-height: 0;
    }

    .MuiTabs-flexContainer {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        @include font-regular(13px);
        color: $primary;
        border-radius: 16px;
        padding: 8px 12px;
        text-transform: inherit;
        min-height: 0;
        line-height: 20px;

        &.Mui-selected {
          background-color: $primaryMedium;
          @include font-bold(13px);
          color: $white;
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .simple-tabpanel {
      .MuiBox-root {
        padding: 0;
      }

      .no-data {
        @include font-bold(13px);
        line-height: 20px;
        color: $primary;
        margin: 0;
      }
    }

    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 48px;

      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
      }
    }

    .tabpanel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .stats {
        color: $primary;
        @include font-regular(13px);

        span {
          @include font-bold(13px);
        }
      }
    }

    .tabpanel-cards {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 16px;
    }

    .details-tabs__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      margin-bottom: 40px;
      padding: 0;

      .right-side {
        display: flex;
        align-items: center;
        gap: 16px;

        .search-input {
          .MuiInputBase-root {
            padding: 14px 12px;
            border-radius: 12px;
            background-color: $white;
            width: 240px;
            height: 42px;
          }

          input {
            padding: 0;
            overflow: hidden;
          }

          fieldset {
            border: none;
          }

          .search-input__end {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  &__table {
    width: 100%;

    .oryx-table thead {
      margin-bottom: 4px;

      .oryx-table-header-row {
        background: transparent;
      }
    }

    .mantine-Paper-root {
      border: none;
      box-shadow: none;
      background-color: transparent;
    }

    &.empty-table {
      tbody {
        .oryx-table-empty-message {
          margin-top: 40px;
        }
      }

      .mantine-vl6xmj {
        display: none;
      }
    }
  }
}
