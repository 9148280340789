@import './../../assets/scss/variables';
@import './../../assets/scss/mixins';

.preload-check-entire {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .oryx-check-details {
    &__header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 999;
      padding-bottom: 0;

      .header-container {
        margin-bottom: 40px;
      }

      .oryx-table-header {
        padding: 0;
        margin: 0;
      }

      .mantine-Paper-root {
        border: none;
        box-shadow: none;
      }
    }

    &__body {
      padding: 0 0 40px 0;

      .oryx-table-header {
        padding: 0 48px;
      }
    }

    &__table {
      /* width: 100%;
      display: flex;
      flex-direction: column; */

      .oryx-table thead {
        margin-bottom: 0;
      }

      .mantine-Paper-root {
        border: none;
        box-shadow: none;
        background-color: transparent;
      }
    }
  }

  .preload-check-item {
    padding: 0 48px;
    margin-bottom: 40px;

    &.first-item {
      margin-top: 40px;
    }

    .oryx-table tbody tr:first-of-type {
      margin-top: 0;
    }
  }

  .rejected-requirements {
    background: $yellowGradient;
    padding: 40px 48px;
    margin-bottom: 0;
  }

  .mantine-vl6xmj {
    display: none;
  }

  h2 {
    @include font-black(15px);
    color: $primary;
    line-height: 24px;
    margin: 0 0 24px 0;
    text-align: start;
    text-transform: capitalize;
  }
}
