@import './../../assets/scss/variables';
@import './../../assets/scss/mixins';

.login-page {
  background-color: $primary;
  height: 100vh;
  background-image: url('./../../assets/images/login-bg.png');
  background-size: cover;

  .right-side-container {
    background-color: $primary;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    .trucks-composition {
      max-width: 580px;
    }

    .oryx-logos {
      position: absolute;
      left: 50px;
      bottom: 50px;
      width: 280px;
      height: 53px;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 380px;
    margin: 0 auto;

    h1 {
      @include font-black(26px);
      color: $white;
      letter-spacing: -1.04px;
      text-align: left;
    }

    p {
      @include font-regular(16px);
      color: $white;
      margin: 0 0 42px 0;
      text-align: left;
    }
  }
}
