@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.oryx-table {
  flex-direction: column;
  width: 100%;

  &.hide-body {
    tbody {
      display: none;
    }
  }

  &.empty {
    tbody {
      tr {
        display: block !important;
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }
      }

      td {
        height: -webkit-fill-available;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background: transparent;
      }

      .oryx-table-empty-message {
        @include font-bold(13px);
        color: $primary;
        line-height: 20px;
        margin: 0;
        text-transform: none;
      }
    }
  }

  &-header {
    &.hide-header {
      display: none;

      & + tbody {
        margin: 0;
      }
    }
  }

  &-header-cell {
    .mantine-TableHeadCell-Content {
      &-Labels {
        flex-direction: row-reverse;
        opacity: 0.6;
      }

      &-Wrapper {
        @include font-regular(12px);
        color: $primary;
        line-height: 18px;
      }
    }
  }

  thead {
    margin-bottom: 40px;
    display: inline;

    tr {
      display: flex;
      align-items: center;
      box-shadow: none;
      background-color: $primaryLighter;

      th {
        border: none;
        flex: 1;
        padding: 16px 24px !important;
      }
    }
  }

  tbody {
    margin-top: 40px;
    display: block;

    tr {
      height: 68px;
      border: none !important;
      display: flex;
      margin-bottom: 4px;
      background-color: $white;
      border-radius: 4px;
    }

    td {
      @include font-regular(12px);
      color: $black;
      line-height: 18px;
      border: none !important;
      display: flex;
      align-items: center;
      flex: 1;
      padding: 16px 24px !important;
      text-align: left;
      text-transform: capitalize;
      background-color: $white;

      & div {
        @include font-regular(12px);
      }

      &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .tolerance-container {
        @include font-bold(13px);
        background: $primaryLight;
        border-radius: 8px;
        color: $primary;
        line-height: 20px;
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .compliance-container {
        @include font-bold(13px);
        border-radius: 8px;
        color: $primary;
        line-height: 20px;
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.level {
          &-1 {
            background-color: $secondaryMedium;
          }

          &-2 {
            background-color: $tertiaryLight;
          }

          &-3 {
            background-color: $tertiaryMedium;
          }

          &-4 {
            background-color: $tertiary;
          }
        }
      }

      .status-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .status-select {
        display: flex;
        align-items: center;
        gap: 16px;

        .MuiSelect-select {
          color: transparent;
          background-image: url('./../../../assets/icons/icon-chevron-down.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          padding: 0;
          width: 16px;
          height: 16px;
        }

        svg,
        fieldset {
          display: none;
        }
      }

      .image-container {
        border-radius: 8px;
        height: 36px;
        width: 36px;

        img {
          border-radius: 8px;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  tfoot {
    outline: none;
  }
}

// Remove Rows Per Page Select
.mantine-InputWrapper-root.mantine-Select-root.mantine-1bauqbv {
  display: none !important;
}
