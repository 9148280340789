@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.block-textfield {
  margin-bottom: 32px;

  label {
    @include font-bold(13px);
    color: $primary;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .textfield {
    width: 100%;

    &.disabled {
      .MuiInputBase-root {
        background-color: $primaryLight;
      }
    }

    &.inline {
      .MuiInputBase-root {
        height: 48px;
      }
    }

    .MuiInputBase-root {
      padding: 14px 12px;
      border-radius: 12px;
      background-color: $white;
      box-sizing: border-box;
    }

    input {
      padding: 0;
      color: $primary;
      @include font-regular(13px);
      overflow: hidden;
      line-height: 20px;
      -webkit-text-fill-color: $primary;
    }

    fieldset {
      border: none;
    }

    .icon-eye {
      cursor: pointer;
    }

    &.error {
      .MuiInputBase-root {
        border: 1px solid $secondaryMedium;
      }

      .MuiFormHelperText-root {
        margin-top: 8px;
        @include font-regular(13px);
        margin-left: 0;
        color: $secondaryMedium;
        line-height: 20px;
      }
    }
  }
}
