@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.block-datepicker {
  margin-bottom: 32px;
  width: 100%;

  &.disabled {
    .mantine-DatePickerInput-input {
      background-color: $primaryLight;
      opacity: 1;
      cursor: default;
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin: 0;
  }

  label {
    @include font-bold(13px);
    color: $primary !important;
    line-height: 20px;
    margin-bottom: 16px;
    position: static;
    transform: none;
    border-radius: 12px;
  }

  .mantine-DatePickerInput-placeholder {
    @include font-regular(13px);
    color: $primary;
    opacity: .4;
    line-height: 20px;
  }

  .mantine-DatePickerInput-input {
    padding: 0;
    border: none;
    color: $primary;
    @include font-regular(13px);
    overflow: hidden;
    line-height: 20px;
    padding: 14px 12px;
    background-color: $white;
    border-radius: 12px !important;
    height: 48px;
  }

  &.error {
    .mantine-DatePickerInput-input {
      border: 1px solid $secondaryMedium;
    }

    .mantine-InputWrapper-error {
      margin-top: 8px;
      @include font-regular(13px);
      margin-left: 0;
      color: $secondaryMedium;
      line-height: 20px;
    }
  }
}
