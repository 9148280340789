// Colors

$primary: #15161f;
$primaryDark: #212132;
$primaryDarker: #0a0a0a;
$primaryDarken: #404040;
$primaryMedium: #656876;
$primaryLight: #d2d4e0;
$primaryLighter: #e6e8f0;

$secondary: #c52536;
$secondaryDark: #891a26;
$secondaryMedium: #dc4252;
$secondaryLight: #e26472;
$secondaryLighter: #efa9b1;

$tertiary: #85b645;
$tertiaryMedium: #c2daa3;
$tertiaryLight: #e1f0cd;

$yellowGradient: linear-gradient(135deg, #efd865 8.75%, #f0eacd 86.25%);

$grey: #e8e8e8;

$black: #000;
$white: #fff;

$colors: (
  'primary': $primary,
  'primaryDark': $primaryDark,
  'primaryMedium': $primaryMedium,
  'primaryLight': $primaryLight,
  'primaryLighter': $primaryLighter,

  'secondary': $secondary,
  'secondaryDark': $secondaryDark,
  'secondaryMedium': $secondaryMedium,
  'secondaryLight': $secondaryLight,
  'secondaryLighter': $secondaryLighter,

  'tertiary': $tertiary,
  'tertiaryMedium': $tertiaryMedium,
  'tertiaryLight': $tertiaryLight,

  'yellowGradient': $yellowGradient,

  'grey': $grey,

  'black': $black,
  'white': $white,
);

$ArialBold: 'Arial-Bold', roboto, helvetica, sans-serif;
$ArialBlack: 'Arial-Black', roboto, helvetica, sans-serif;
$ArialRegular: 'Arial-Regular', roboto, helvetica, sans-serif;
