@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.oryx-check-details {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__container {
    padding: 48px;
  }

  &__header {
    background-color: $primaryLighter;
    padding: 40px 48px 20px 48px;
    gap: 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;

    .status-buttons {
      display: flex;
      align-items: center;
      gap: 12px;

      button {
        img {
          padding: 9px;
        }
      }
    }

    .badge {
      @include font-bold(12px);
      border-radius: 8px 2px;
      color: $primary;
      line-height: 18px;
      padding: 12px 10px;
      min-width: 100px;

      &.approved {
        background-color: $tertiaryMedium;
      }

      &.rejected {
        background-color: $secondaryMedium;
      }

      &.to_do {
        background-color: $primaryDark;
        color: $white;
      }

      &.hold {
        background-color: $primaryDark;
        color: $white;
      }

      &.pending_request {
        background-color: $primaryDark;
        color: $white;
      }
    }

    .header-container {
      display: flex;
      justify-content: space-between;
    }

    .filters-container {
      display: flex;
      margin-top: 20px;
      padding: 16px 0;
      gap: 12px;

      .filter-chip {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        border-radius: 16px;
        border: 1px solid $primary;

        span {
          @include font-regular(12px);
          color: $primary;
          line-height: 18px;
          padding: 0;
          margin-right: 8px;
        }

        svg {
          margin: 0;
        }
      }
    }

    p {
      margin: 0;
    }

    .left-side {
      display: flex;
      align-items: center;
      gap: 16px;

      .description {
        text-align: left;
      }
    }

    .right-side {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__body {
    background: url('./../../../assets/images/stats-bg.png') no-repeat;
    background-size: cover;
    padding: 40px 48px;
    position: relative;
    flex: 1;

    .cards-container {
      display: flex;
      align-items: stretch;
      gap: 16px;
    }

    .card {
      background-color: $white;
      border-radius: 12px;
      margin-bottom: 40px;
      padding: 24px;
      flex: 1;

      h3 {
        @include font-bold(15px);
        color: $primary;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 24px;
        text-align: left;
      }

      .card-description {
        &__item {
          display: flex;
          justify-content: space-between;
          align-items: normal;

          .label,
          .value {
            color: $primary;
            line-height: 20px;
            margin: 0;
          }

          .label {
            @include font-regular(13px);
          }

          .value * {
            @include font-bold(13px);
            text-align: right;
          }
          
          .value span {
            display: block;
          }
        }
      }
    }

    .rejected-requirements {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .right-side {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }

      &__title {
        @include font-black(15px);
        color: $primary;
        line-height: 24px;
        margin: 0;
      }

      &__table {
        width: 100%;

        .oryx-table thead {
          margin-bottom: 4px;

          .oryx-table-header-row {
            background: transparent;
          }
        }

        .mantine-Paper-root {
          border: none;
          box-shadow: none;
          background-color: transparent;
        }

        &.empty-table {
          tbody {
            .oryx-table-empty-message {
              margin-top: 40px;
            }
          }

          .mantine-vl6xmj {
            display: none;
          }
        }
      }
    }

    .dashboard-tabs__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .MuiTabs-root {
      min-height: 0;
    }

    .MuiTabs-flexContainer {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        @include font-regular(13px);
        color: $primary;
        border-radius: 16px;
        padding: 8px 12px;
        text-transform: inherit;
        min-height: 0;
        line-height: 20px;

        &.Mui-selected {
          background-color: $primaryMedium;
          @include font-bold(13px);
          color: $white;
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .empty-table-message {
      @include font-bold(13px);
      color: $primary;
      line-height: 20px;
      margin: 40px 0 0 0;
    }
  }
}
