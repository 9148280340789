@import './../../assets/scss/variables';
@import './../../assets/scss/mixins';

.preload-check-details {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .details-container {
    display: flex;
    flex: 1;
  }
}

.print-template {
  padding: 24px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: calc(100vh - 48px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -99;

  &__top {
    .print-template__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      img {
        height: 34px;
      }

      .block-title {
        .subtitle,
        .title {
          margin: 0;
        }

        .title {
          @include font-black(12px);
          color: $primary;
          line-height: 17px;
        }

        .subtitle {
          @include font-regular(8px);
          text-transform: uppercase;
          color: $primary;
          line-height: 9px;
        }
      }

      .block-desc {
        text-align: center;

        p {
          @include font-regular(10px);
          color: $primary;
          margin: 0;
          line-height: 18px;
        }
      }
    }

    .card-preload-check {
      display: flex;
      justify-content: space-between;
      gap: 40px;

      .card-right,
      .card-left {
        flex: 1;

        .card-item {
          display: flex;
          justify-content: space-between;

          &:nth-child(odd) {
            border-top: 1px solid $primaryLight;
            border-bottom: 1px solid $primaryLight;
          }

          p {
            margin: 0;
          }

          .label,
          .value {
            @include font-regular(9px);
            color: $primary;
            line-height: 20px;
          }

          .value {
            @include font-bold(9px);
          }
        }
      }
    }

    .card-categories {
      &__item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 8px 40px;

        .category-item {
          flex-basis: calc(50% - 20px);
          text-align: start;
          padding-bottom: 8px;
          border-bottom: .5px solid $primaryLighter;

          .category-title {
            @include font-black(10px);
            line-height: 14px;
            color: $primary;
            margin-top: 0;
            margin-bottom: 4px;
            text-transform: capitalize;
          }

          .rejected-requirements__item {
            padding-left: 8px;
            border-left: 1px solid $black;
            display: flex;
            gap: 0 8px;

            .labels {
              display: flex;
              flex-direction: column;

              .label {
                @include font-regular(10px);
                color: $primary;
                line-height: 11.5px;
                margin: 0;
                opacity: 0.6;

                &.requirement {
                  @include font-bold(10px);
                  opacity: 1;
                }
              }
            }

            .values {
              display: flex;
              flex-direction: column;

              .value {
                @include font-regular(9px);
                color: $primary;
                line-height: 10px;
                margin: 0;
              }
            }
          }

          .all-requirements-met {
            @include font-regular(10px);
            line-height: 18px;
            color: $black;
            margin: 0;
          }
        }
      }

      &__title {
        @include font-regular(9px);
        line-height: 18px;
        color: $primary;
        margin: 8px 0;
        text-align: left;
      }
    }
  }

  &__bottom {
    .truck-acceptance {
      display: flex;
      margin-bottom: 8px;
    }

    .truck-acceptance__item {
      border: 1px solid $black;
      display: flex;

      .label {
        @include font-regular(10px);
        line-height: 18px;
        color: $black;
        margin: 0;
        padding: 3px 32px 3px 16px;
      }

      .value {
        border-left: 1px solid $black;
        width: 70px;
      }
    }

    .block-signature {
      &__title {
        @include font-regular(9px);
        color: $primary;
        line-height: 18px;
        margin-bottom: 4px;
        text-align: left;
      }

      &__items {
        display: flex;
      }

      &__item {
        flex: 1;

        .role {
          @include font-bold(10px);
          color: $black;
          padding: 2px 8px;
          line-height: 28px;
          margin: 0;
          text-align: left;
        }

        .signature-box {
          border: 1px solid $black;
          padding: 4px 8px;
          height: 40px;
          text-align: left;

          .label {
            @include font-bold(8px);
            line-height: 18px;
            color: $black;
          }
        }

        .date {
          display: flex;
          padding: 0 8px;
          justify-content: space-between;

          .label {
            @include font-regular(8px);
            line-height: 20px;
            color: $primary;
            margin: 0;
          }

          .value {
            @include font-bold(8px);
            line-height: 20px;
            color: $primary;
            margin: 0;
          }
        }
      }
    }
  }
}