@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.confirm-modal {
  .MuiPaper-root {
    padding: 24px;
    width: 330px;
    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  &-header {
    margin-bottom: 40px;

    .MuiDialogTitle-root {
      @include font-bold(13px);
      color: $primary;
      padding: 0;
      line-height: 20px;
    }

    p {
      @include font-regular(13px);
      color: $primary;
      margin: 0;
      line-height: 20px;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
  }
}
