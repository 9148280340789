@import './variables';

@mixin font-bold($size) {
  font-family: $ArialBold !important;
  font-size: $size !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin font-black($size) {
  font-family: $ArialBlack !important;
  font-size: $size !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin font-regular($size) {
  font-family: $ArialRegular !important;
  font-size: $size !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
