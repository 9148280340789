@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.oryx-select__paper {
  left: 110px !important;

  &.lang-select__paper {
    left: 174px !important;
  }

  &.table-status-select__paper {
    right: 200px !important;
    left: auto !important;
    width: 80px;
  }

  .MuiList-root {
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.1);
    padding: 8px;
  }

  .MuiMenuItem-root {
    @include font-regular(12px);
    line-height: 18px;
    color: $primary;
    min-height: auto;
    padding: 8px;

    &.Mui-selected {
      position: relative;

      &::before {
        content: '';
        width: 14px;
        height: 10px;
        position: absolute;
        background: url('./../../../assets/icons/icon-check.svg') no-repeat;
        background-size: contain;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.tabs-container {
  text-align: left;
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 48px);
  padding: 24px;
  background-color: $black;

  .tabs-select {
    width: fit-content;
    border-radius: 8px;
    margin-bottom: 60px;
    display: block;

    .MuiSelect-select {
      background-color: $primaryDark;
      border-radius: 8px;
      padding: 4px 27px !important;
      height: 18px !important;
      min-height: 18px !important;
      color: $white;
      @include font-regular(12px);
      width: auto;
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        width: 11px;
        height: 14px;
        position: absolute;
        background: url('./../../../assets/icons/icon-building.svg') no-repeat;
        background-size: contain;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        content: '';
        width: 9px;
        height: 14px;
        position: absolute;
        background: url('./../../../assets/icons/icon-chevron-right.svg') no-repeat;
        background-size: contain;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .MuiSvgIcon-root,
    input {
      display: none;
    }
  }

  .logo {
    height: 34px;
    margin-bottom: 24px;
    width: 94px;
  }

  .block-tabs {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .tab {
      display: flex;
      align-items: center;
      text-decoration: none;
      opacity: 0.4;

      &-icon {
        width: 20px;
        margin-right: 8px;
      }

      span {
        @include font-regular(13px);
        color: $white;
      }

      &:hover {
        opacity: 1;

        span {
          @include font-bold(13px);
        }
      }

      &.active {
        opacity: 1;

        span {
          @include font-bold(13px);
        }
      }
    }

    .icon {
      // margin-right: 27px;
    }
  }

  /* Lang Select Style */
  .lang-select {
    width: 100%;
    margin-bottom: 24px;

    .MuiSelect-select {
      background-color: transparent;
      padding: 14px 0 !important;
      height: 20px !important;
      min-height: 20px !important;
      color: $white;
      @include font-bold(13px);
      line-height: 20px;
      display: flex;
      align-items: center;
      position: relative;

      &::after {
        content: '';
        width: 9px;
        height: 14px;
        position: absolute;
        background: url('./../../../assets/icons/icon-chevron-right.svg') no-repeat;
        background-size: contain;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .block-user-name {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      @include font-black(15px);
      line-height: 24px;
      color: $white;
      margin: 0;
    }
  }
}
