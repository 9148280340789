@import './../../../assets/scss/variables';
@import './../../../assets/scss/mixins';

.filter-modal {
  background-color: $primaryLighter;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: -7px 0px 8px 0px rgb(0 0 0 / 12%);
  width: 400px;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  transition: right 600ms 100ms ease-out;
  height: 100vh;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .filter-top {
    flex: 1;
  }

  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 8px 24px;

    h3 {
      @include font-black(15px);
      color: $primary;
      line-height: 24px;
      margin: 0;
    }
  }

  .filter-body {
    padding: 24px;

    .block-status {
      margin-bottom: 32px;

      &__label {
        @include font-bold(13px);
        color: $primary;
        line-height: 20px;
        margin-bottom: 16px;
      }

      &__container {
        display: flex;
        align-items: center;
        gap: 8px;

        button {
          min-width: fit-content;
        }
      }
    }
  }

  .filter-footer {
    padding: 24px;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    align-items: center;
  }

  .form-modal__container {
    background-color: $white;
    box-sizing: border-box;
    padding-top: 40px;
    height: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    form > div {
      margin-bottom: 20px;
    }
  }

  &.open {
    right: 0;
  }

  &.close {
    right: -400px;
  }
}
